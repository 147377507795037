<template>
  <v-card>
    <v-card-title>
      Cargar imágenes
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      class="text-right"
      >
      <v-file-input
        filled
        v-model="images"
        label="Imágenes"
        multiple
        accept="image/*"
        ></v-file-input>

      <v-btn
        tile
        color="primary"
        @click="edit"
        >
        Guardar
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditProduct } from '@/graphql/mutations/product'

export default {
  data () {
    return {
      images: [],
      imagesAttributes: []
    }
  },

  props: {
    product: {
      required: true,
      type: Object
    }
  },

  watch: {
    images (val) {
      this.imagesAttributes = []
      val.forEach( image => {
        this.imagesAttributes.push({
          name: image.name,
          size: image.size,
          image: image
        })
      })
    }
  },

  methods: {
    edit () {
      this.$apollo.mutate({
        mutation: EditProduct,
        variables: {
          input: {
            id: this.product.id,
            attributes: {
              masterAttributes: {
                id: this.product.master.id,
                imagesAttributes: this.imagesAttributes
              }
            }
          }
        }
      }).then( res => {
        this.$emit('reload')
        this.$emit('close')
      })
    }
  }
}
</script>
