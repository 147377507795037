<template>
  <v-card>
    <v-card-title>
      Imágenes

      <v-spacer></v-spacer>

      <v-icon
        @click="$emit('close')"
        large
        >
        mdi-close-circle-outline
      </v-icon>
    </v-card-title>
    <v-card-text>
      <images
        :variant="variant"
        @reload="$emit('reload')"
        :key="variant.images.length"
        ></images>
    </v-card-text>
  </v-card>
</template>

<script>
import Images from './Images'

export default {
  props: {
    variant: {
      required: true,
      type: Object
    }
  },

  components: {
    Images
  }
}
</script>
