import gql from 'graphql-tag'

export const Attachment = gql`
  query attachment($id: ID!) {
    attachment(id: $id) {
      id
      name
      size
      url
    }
  } 
`

export const Attachments = gql`
  query attachments($id: ID!) {
    attachments(id: $id) {
      id
      name
      size
      url
    }
  } 
`
