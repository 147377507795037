<template>
  <v-container
    v-if="product"
    style="max-width: 1200px"
    >
    <Header
      :title="product.name"
      subtitle="Detalle de producto"
      ></Header>

    <v-alert
      dense
      type="info"
      outlined
      v-if="!product.canShip"
      >
      Las dimensiones del producto superan las máximas permitidas por Sendcargo, por lo que deberá ser despachado por un método de logística propio.
    </v-alert>

    <v-tabs
      v-model="tab"
      dense
      >
      <v-tab>General</v-tab>
      <v-tab
        >Talla y color</v-tab>
      <v-tab>Especificaciones</v-tab>
      <v-tab
        v-if="product.productType == 'digital' && false"
        >Archivos</v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <Show
          v-if="tab == 0"
          :product="product"
          @reload="fetchProduct"
          />
        <div
          v-if="tab == 1"
          >
          <Variants
            :product="product"
            :key="JSON.stringify(product.variants) + '_' + product.optionTypes.length"
            @reload="fetchProduct"
            />
        </div>

        <Specifications
          v-if="tab == 2"
          :product="product"
          :key="JSON.stringify(product)"
          @reload="fetchProduct"
          />

        <Attachments
          v-if="tab == 3"
          :productId="product.id"
          :key="JSON.stringify(product)"
          @reload="fetchProduct"
          />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Product } from '@/graphql/queries/products'
import Show from '@/components/admin/products/Show'
import Variants from '@/components/admin/products/Variants'
import Specifications from '@/components/admin/products/Specifications'
import Attachments from '@/components/admin/products/Attachments'
import Header from '@/components/admin/shared/Header'

export default {
  data () {
    return {
      product: null,
      tab: 0,
    }
  },

  components: {
    Show,
    Header,
    Variants,
    Attachments,
    Specifications
  },

  mounted () {
    this.fetchProduct()
  },

  methods: {
    fetchProduct () {
      this.$apollo.query({
        query: Product,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.product = res.data.product
      })
    }
  }
}
</script>

<style scoped>
>>>.v-slide-group__content {
  background: #ebeef3;
}
>>>.v-slide-group__prev {
  display: none !important
}
>>>.v-slide-group__next {
  display: none !important
}
</style>

