<template>
  <div
    v-if="prod"
    >
    <v-form
      v-model="valid"
      lazy-validation
      ref="form"
      >
      <v-row
        class="py-3"
        >
        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="prod.name"
            filled
            :rules="required"
            label="Nombre"
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="prod.sku"
            filled
            hint="Opcional"
            persistent-hint
            label="SKU"
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="prod.masterAttributes.pricesAttributes[0].amount"
            label="Precio"
            placeholder="1000"
            :rules="required"
            prefix="$"
            filled
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="prod.masterAttributes.pricesAttributes[0].discount"
            label="Descuento"
            placeholder="10"
            :rules="priceRules"
            suffix="%"
            filled
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="product.displayPrice"
            label="Precio con descuento"
            readonly
            filled
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="6"
          >
          <v-text-field
            v-model="prod.masterAttributes.stockItemsAttributes[0].stock"
            label="Stock"
            placeholder="5"
            filled
            ></v-text-field>
        </v-col>

        <v-col
          class="py-0"
          cols="12"
          md="12"
          >
          <v-autocomplete
            :items="categories"
            item-text="fullName"
            item-value="id"
            v-model="prod.categoryIds"
            label="Cateogrías"
            multiple
            small-chips
            deletable-chips
            filled
            ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          class="caption pt-0"
          >
          Las medidas corresponden al producto embalado.
        </v-col>
        <template
          v-for="field in ['height', 'depth', 'length', 'weight']"
          >
          <v-col
            class="py-0"
            cols="12"
            md="6"
            >
            <v-text-field
              v-model="prod.masterAttributes[field]"
              :label="$t('fields.product.' + field)"
              filled
              ></v-text-field>
          </v-col>
        </template>

        <v-col
          class="py-0"
          cols="12"
          md="12"
          >
          <v-textarea
            v-model="prod.description"
            label="Descripción"
            placeholder="Lorem ipsum dolorem..."
            filled
            ></v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <div
      class="text-right"
      >
      <v-btn
        tile
        color="primary"
        @click="edit"
        >
        Guardar
      </v-btn>
    </div>

    <v-snackbar
      :timeout="3000"
      :color="alert.type"
      v-model="alert.active"
      >
      <v-icon
        class="mr-3"
        >mdi-check-circle</v-icon> {{ alert.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { EditProduct } from '@/graphql/mutations/product'
import { Categories } from '@/graphql/queries/categories'

export default {
  data () {
    return {
      valid: true,
      prod: {
        name: '',
        productType: 'standard',
        description: '',
        sku: '',
        categoryIds: [],
        masterAttributes: {
          sku: '',
          height: null,
          length: null,
          depth: null,
          weight: null,
          pricesAttributes: [
            {
              amount: 0,
              discount: 0,
            }
          ],
          stockItemsAttributes: [
            {
              stock: 0,
              stockLocationId: 1,
            }
          ]
        }
      },
      categories: [],
      types: [
        {
          text: 'Estándar',
          value: 'standard'
        },
        {
          text: 'Digital',
          value: 'digital'
        },
      ],
      alert: {
        active: false,
        message: '',
        type: 'success'
      },
      required: [
        v => !!v || 'Campo obligatorio',
      ],
      priceRules: [
        v => (v || 100) <= 100 || 'Debe ser menor o igual a 100',
        v => (v || 0) >= 0 || 'Debe ser mayor o igual a 0'
      ]
    }
  },

  created () {
    // Refactor this piece of s LKJDAHAAAA and just map values
    // A lot cleaner than delete a fking bunch of attrs
    this.fetchCategories()

    this.prod.name = this.product.name
    this.prod.description = this.product.description
    this.prod.productType = this.product.productType
    this.prod.sku = this.product.master.sku
    this.prod.categoryIds = this.product.categoryIds
    this.prod.masterAttributes.sku = this.product.master.sku
    this.prod.masterAttributes.id = this.product.master.id
    this.prod.masterAttributes.height = this.product.master.height
    this.prod.masterAttributes.depth = this.product.master.depth
    this.prod.masterAttributes.length = this.product.master.length
    this.prod.masterAttributes.weight = this.product.master.weight
    this.prod.masterAttributes.pricesAttributes[0].id = this.product.master.prices[0].id
    this.prod.masterAttributes.pricesAttributes[0].discount = this.product.master.prices[0].discount
    this.prod.masterAttributes.pricesAttributes[0].amount = this.product.fullPrice
    this.prod.masterAttributes.stockItemsAttributes[0].id = this.product.master.stockItems[0].id
    this.prod.masterAttributes.stockItemsAttributes[0].stock = this.product.totalStock
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  methods: {
    fetchCategories () {
      this.$apollo.query({
        query: Categories,
      }).then( res => {
        this.categories = res.data.categories
      })
    },

    edit () {
      if (this.valid) this.valid = this.$refs.form.validate()
      
      if (this.valid) {
        this.$apollo.mutate({
          mutation: EditProduct,
          variables: {
            input: {
              id: this.product.id,
              attributes: this.prod
            }
          }
        }).then( res => {
          this.alert = {
            active: true,
            message: '¡Producto editado con éxito!',
            type: 'success'
          }
          this.$emit('reload')
        })
      }
    }
  }
}
</script>
