<template>
  <v-card>
    <v-card-title>
      Eliminar variante {{ variant.sku }}
    </v-card-title>
    <v-card-text>
      <div>
        ¿Está seguro de que desea eliminar la variante <span class="font-weight-bold">{{ variant.sku }}</span>? Esta acción no se puede deshacer.
      </div>

      <v-divider
        class="my-3"
        >
      </v-divider>

      <div
        class="text-right"
        >
        <v-btn
          text
          @click="$emit('close')"
          class="mr-3"
          >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          tile
          @click="deleteVariant"
          >
          Confirmar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DeleteVariant } from '@/graphql/mutations/products/variant'

export default {
  props: {
    variant: {
      type: Object,
      required: true
    }
  },

  methods: {
    deleteVariant () {
      this.$apollo.mutate({
        mutation: DeleteVariant,
        variables: {
          input: {
            variantId: this.variant.id
          }
        }
      }).then( res => {
        this.$emit('reload')
        this.$emit('close')
      })
    }
  }
}
</script>
