<template>
  <div
    class="d-flex flex-column"
    >
    <v-scroll-x-transition
      hide-on-leave
      >
      <div
        v-if="images.length"
        >
        <v-img
          :key="selected.id"
          class="rounded-lg text-right"
          v-if="selected"
          :src="url(selected.url)"
          height="200"
          >
          <v-btn
            icon
            @click="deleteImage(selected.id)"
            color="error"
            >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-img>
      </div>
      <v-card
        height="200"
        v-else
        class="grey lighten-2 d-flex align-center justify-center"
        >
      </v-card>
    </v-scroll-x-transition>

    <div
      class="mt-3 d-flex fill-width thumbnails"
      :class="$vuetify.breakpoint.mdAndUp ? 'flex-wrap justify-center' : 'flex-row justify-start'"
      :style="$vuetify.breakpoint.mdAndUp ? '' : styles"
      >
      <v-card
        flat
        class="mb-3 mx-1"
        height="55"
        width="55"
        >
        <v-btn
          color="primary"
          class="rounded-lg"
          fab
          depressed
          @click="modal = true"
          >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card>

      <v-card
        style="cursor: pointer"
        flat
        v-for="(image, idx) in images"
        :key="idx"
        :class="selectClass(image, idx)"
        class="mb-3 mx-1 rounded-lg"
        height="55"
        width="55"
        >
        <v-img
          @click="selected = image"
          height="55"
          width="55"
          :src="url(image.thumbUrl)"
          ></v-img>
      </v-card>

      <v-dialog
        v-model="modal"
        width="800"
        :fullscreen="$vuetify.breakpoint.mobile"
        >
        <Create
          :product="product"
          @reload="$emit('reload')"
          @close="modal = false"
          />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { DeleteImage } from '@/graphql/mutations/product'
import Create from '@/components/admin/products/images/Create'

export default {
  data () {
    return {
      selected: null,
      modal: false,
    }
  },

  mounted () {
    if (this.images.length) {
      this.selected = this.images[0]
    }
  },

  props: {
    product: {
      required: true,
      type: Object
    }
  },

  components: {
    Create
  },

  computed: {
    images () {
      if (this.product) {
        return this.product.images.sort( v => v.id)
      }
    },

    styles () {
      return 'overflow: scroll;'
    }
  },

  methods: {
    url (url) {
      return process.env.VUE_APP_RAILS_URL + url
    },

    selectClass (image, idx) {
      var klass = []
      if (image == this.selected) {
        klass.push('elevation-3')
      }

      return klass.join(' ')
    },
    
    deleteImage (id) {
      this.$apollo.mutate({
        mutation: DeleteImage,
        variables: {
          input: {
            imageId: id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    }
  }
}
</script>

<style scoped>
.thumbnails {
  max-height: 150px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.thumbnails::-webkit-scrollbar {
  display: none;
}
</style>
