<template>
  <v-card>
    <v-card-title>
      Editar variante
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text
      v-if="product"
      >
      <v-form
        v-if="product"
        v-model="valid"
        ref="form"
        lazy-validation
        >
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="editedVariant.sku"
              filled
              label="SKU"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="editedVariant.pricesAttributes[0].amount"
              filled
              label="Precio"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="editedVariant.stockItemsAttributes[0].stock"
              filled
              label="Stock"
              ></v-text-field>
          </v-col>

          <template
            v-for="field in ['height', 'length', 'depth', 'weight']"
            >
            <v-col
              cols="12"
              md="6"
              class="pb-0"
              >
              <v-text-field
                v-model="variant[field]"
                filled
                :label="$t('fields.product.' + field)"
                :rules="requiredRules"
                ></v-text-field>
            </v-col>
          </template>

          <template
            v-for="(optionValue, idx) in editedVariant.variantOptionValuesAttributes"
            >
            <v-col
              cols="6"
              class="pb-0"
              >
              <v-autocomplete
                :items="product.optionTypes"
                v-model="optionTypes[idx]"
                return-object
                item-text="name"
                filled
                label="Opción"
                ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
              >
              <v-autocomplete
                v-if="optionTypes[idx]"
                :items="optionTypes[idx].optionValues"
                v-model="optionValue.optionValueId"
                item-text="value"
                item-value="id"
                filled
                label="Valor"
                ></v-autocomplete>
            </v-col>
          </template>

          <v-col
            cols="12"
            class="text-right pt-0 mb-3"
            >
            <v-btn
              text
              color="primary"
              @click="addOptions"
              :disabled="optionTypes.length == product.optionTypes.length"
              >
              Agregar opción
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div
        class="text-right"
        >
        <v-btn
          tile
          color="primary"
          @click="editVariant"
          :disabled="!product"
          :loading="!product"
          >
          Editar
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text
      v-else
      >
      <v-card
        class="d-flex flex-row align-center justify-center"
        height="300"
        flat
        >
        <v-progress-circular
          :size="70"
          :width="7"
          color="secondary"
          indeterminate
          ></v-progress-circular>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { Required } from '@/utils/rules'
import { EditVariant } from '@/graphql/mutations/products/variant'
import { Product } from '@/graphql/queries/products'

export default {
  data () {
    return {
      valid: true,
      optionTypes: [null],
      product: null,
      editedVariant: {
        sku: null,
        height: null,
        depth: null,
        weight: null,
        length: null,
        pricesAttributes: [
          {
            amount: 0,
            discount: 0
          }
        ],
        stockItemsAttributes: [
          {
            stock: 0,
            stockLocationId: 1,
          }
        ],
        variantOptionValuesAttributes: [
          {
            optionValueId: null
          },
        ]
      },
      requiredRules: Required
    }
  },

  props: {
    variant: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.fetchProduct()
    this.editedVariant.sku = this.variant.sku
    this.editedVariant.height = this.variant.height
    this.editedVariant.length = this.variant.length
    this.editedVariant.depth = this.variant.depth
    this.editedVariant.weight = this.variant.weight
    this.editedVariant.pricesAttributes = JSON.parse(JSON.stringify(this.variant.prices))
    this.editedVariant.stockItemsAttributes = JSON.parse(JSON.stringify(this.variant.stockItems))
    this.editedVariant.variantOptionValuesAttributes = JSON.parse(JSON.stringify(this.variant.variantOptionValues))
  },

  methods: {
    fetchProduct () {
      this.$apollo.query({
        query: Product,
        variables: {
          id: this.variant.productId
        }
      }).then( res => {
        this.product = res.data.product
        this.variant.variantOptionValues.forEach( (vo, idx) => {
          var optionType = vo.optionValue.optionType
          this.optionTypes[idx] = res.data.product.optionTypes.find( ot => ot.id == optionType.id )
        })

        this.editedVariant.pricesAttributes.forEach( p => {
          delete p['__typename']
          delete p['stringify']
        })

        this.editedVariant.stockItemsAttributes.forEach( p => {
          delete p['__typename']
        })

        this.editedVariant.variantOptionValuesAttributes.forEach( p => {
          delete p['__typename']
          delete p['optionValue']
          delete p['variant']
        })
      })
    },

    addOptions () {
      this.editedVariant.variantOptionValuesAttributes.push({
        optionValueId: null
      })

      this.optionTypes[this.optionTypes.length] = this.product.optionTypes[this.optionTypes.length]
      this.$forceUpdate()
    },

    editVariant () {
      if (this.valid) { this.valid = this.$refs.form.validate() }

      if (this.valid) {
        this.$apollo.mutate({
          mutation: EditVariant,
          variables: {
            input: {
              variantId: this.variant.id,
              attributes: this.editedVariant
            }
          }
        }).then( res => {
          this.$emit('reload')
          this.$emit('close')
        })
      }
    }
  }
}
</script>
