<template>
  <div>
    <div
      class="text-right d-flex flex-row align-center"
      >
      <v-file-input
        filled
        v-model="append"
        label="Archivos"
        multiple
        hide-details
        class="mr-3"
        ></v-file-input>
      <v-btn
        tile
        color="primary"
        @click="addAttachments"
        :disabled="!append.length"
        >
        subir
      </v-btn>
    </div>

    <v-divider
      class="mt-4 mb-2"
      ></v-divider>

    <v-subheader>Tus archivos subidos</v-subheader>

    <v-simple-table
      fixed-header
      height="400px"
      >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Nombre
            </th>
            <th class="text-left">
              Tamaño
            </th>
            <th class="text-left">
              Formato
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="attachment in attachments"
            :key="attachment.name"
            >
            <td>{{ attachment.name }}</td>
            <td>{{ (attachment.size / (1024 ** 2)).toFixed(2) }} Mb</td>
            <td>{{ attachment.name.split('.').slice(-1)[0] }}</td>
            <td
              class="text-right"
              >
              <v-icon
                color="error"
                @click="deleteAttachment(attachment.id)"
                >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { EditProduct } from '@/graphql/mutations/product'
import { DeleteAttachment } from '@/graphql/mutations/attachment'
import { Attachments } from '@/graphql/queries/attachments'

export default {
  data () {
    return {
      attachments: [],
      newAttachments: [],
      append: []
    }
  },

  mounted () {
    this.fetchAttachments()
  },

  props: {
    productId: {
      required: true,
      type: Number | String
    }
  },

  watch: {
    append (files) {
      this.newAttachments = []
      files.forEach( (file, idx) => {
        console.log(file)
        this.newAttachments.push({
          file: file,
          name: file.name,
          size: file.size
        })
      })
    }
  },

  computed: {
    styles () {
      return 'overflow: scroll;'
    }
  },

  methods: {
    fetchAttachments () {
      this.$apollo.query({
        query: Attachments,
        variables: {
          id: this.productId
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.attachments = res.data.attachments
      })
    },

    url (url) {
      return process.env.VUE_APP_RAILS_URL + url
    },

    addAttachments () {
      this.$apollo.mutate({
        mutation: EditProduct,
        variables: {
          input: {
            id: this.productId,
            attributes: {
              attachmentsAttributes: [ ...this.newAttachments ]
            }
          }
        }
      }).then( res => {
        this.fetchAttachments()
      }).catch( err => {
        console.log(err)
      })
    },

    deleteAttachment (id) {
      this.$apollo.mutate({
        mutation: DeleteImage,
        variables: {
          input: {
            imageId: id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },

    openAttachment (attachment) {
      window.open(this.url(attachment.url))
    },

    deleteAttachment (id) {
      this.$apollo.mutate({
        mutation: DeleteAttachment,
        variables: {
          input: {
            id: id
          }
        }
      }).then( res => {
        this.fetchAttachments()
      })
    }
  }
}
</script>

<style scoped>
.thumbnails {
  max-height: 150px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.thumbnails::-webkit-scrollbar {
  display: none;
}
</style>
