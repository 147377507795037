import gql from 'graphql-tag'

export const CreateVariant = gql`
  mutation createVariant($input: CreateVariantInput!) {
    createVariant(input: $input) {
      success
    }
  }
`

export const EditVariant = gql`
  mutation editVariant($input: EditVariantInput!) {
    editVariant(input: $input) {
      success
    }
  }
`

export const DeleteVariant = gql`
  mutation deleteVariant($input: DeleteVariantInput!) {
    deleteVariant(input: $input) {
      success
    }
  }
`
