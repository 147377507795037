<template>
  <div>
    <v-dialog
      v-model="create"
      width="600px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      >
      <Create
        :product="product"
        @close="create = false"
        @reload="fetchVariants"
        />
    </v-dialog>

    <v-dialog
      v-model="detail"
      width="600px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      >
      <detail
        v-if="selected"
        :variant="selected"
        :key="selected.id"
        @close="detail = false"
        @reload="fetchVariants"
        ></detail>
    </v-dialog>

    <div
      class="text-subtitle-1 mb-2 d-flex align-center"
      >
      Variantes de Talla y Color
      <v-spacer></v-spacer>
      <v-btn depressed class="rounded-lg" @click="create = true" color="primary">crear</v-btn>
    </div>

    <v-alert
      outlined
      type="info"
      v-if="!variants.length"
      class="rounded-lg"
      >
      Aún no tienes variantes creadas, haz click <v-btn class="rounded-lg" small depressed tile @click="create = true" color="primary">aquí</v-btn> para crear una.
    </v-alert>
    <div
      v-else
      >
      <Header />
      <template
        v-for="variant in variants"
        >
        <Variant
          :variant="variant"
          @reload="fetchVariants"
          @select="select"
          />
      </template>
    </div>
  </div>
</template>

<script>
import { AdminVariants } from '@/graphql/queries/variants'

import Create from '@/components/admin/products/variants/Create'
import Header from '@/components/admin/products/variants/Header'
import Variant from '@/components/admin/products/variants/Variant'
import Detail from '@/components/admin/products/variants/Detail'

export default {
  data () {
    return {
      create: false,
      detail: false,
      variants: []
    }
  },

  components: {
    Create,
    Header,
    Variant,
    Detail
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.fetchVariants()
  },

  methods: {
    select (variant) {
      this.selected = variant
      this.detail = true
    },

    fetchVariants () {
      this.$apollo.query({
        query: AdminVariants,
        variables: {
          productId: this.product.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.variants = res.data.variants

        if (this.selected) {
          this.selected = this.variants.find( variant => variant.id == this.selected.id )
        }
      })
    },
  }
}
</script>
