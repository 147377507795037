<template>
  <v-card>
    <v-card-title>
      Crear variante
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        >
        <v-file-input
          label="Imágenes"
          filled
          v-model="images"
          multiple
          accept="image/*"
          truncate-length="15"
          ></v-file-input>

        <div
          v-if="images.length"
          class="pb-3 d-flex flex-wrap"
          >
          <v-card
            v-for="(image, idx) in images"
            :key="idx"
            class="mr-3 mb-3"
            >
            <v-img
              height="75"
              width="75"
              :src="imagesUrl[idx]"
              >
              <div
                class="fill-height fill-width d-flex align-center justify-center text-center"
                >
                <v-icon
                  @click="removeImage(image)"
                  color="error darken-1"
                  >
                  mdi-close
                </v-icon>
              </div>
            </v-img>
          </v-card>
        </div>

        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="variant.sku"
              filled
              label="SKU"
              persistent-hint
              hint="*Opcional"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="variant.pricesAttributes[0].amount"
              filled
              :rules="requiredRules"
              label="Precio"
              ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
            >
            <v-text-field
              v-model="variant.stockItemsAttributes[0].stock"
              filled
              label="Stock"
              ></v-text-field>
          </v-col>

          <template
            v-for="field in ['height', 'length', 'depth', 'weight']"
            >
            <v-col
              cols="12"
              md="6"
              class="pb-0"
              >
              <v-text-field
                v-model="variant[field]"
                filled
                :label="$t('fields.product.' + field)"
                ></v-text-field>
            </v-col>
          </template>

          <template
            v-for="(optionValue, idx) in variant.variantOptionValuesAttributes"
            >
            <v-col
              cols="6"
              class="pb-0"
              >
              <v-autocomplete
                :items="product.optionTypes"
                :key="optionTypes.length"
                :rules="requiredRules"
                v-model="optionTypes[idx]"
                return-object
                item-text="name"
                filled
                label="Opción"
                ></v-autocomplete>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
              >
              <v-autocomplete
                v-if="optionTypes[idx]"
                :items="optionTypes[idx].optionValues"
                :rules="requiredRules"
                v-model="optionValue.optionValueId"
                item-text="value"
                item-value="id"
                filled
                label="Valor"
                ></v-autocomplete>
            </v-col>
          </template>

          <v-col
            cols="12"
            class="text-right pt-0 mb-3"
            >
            <v-btn
              text
              color="primary"
              @click="addOptions"
              :disabled="optionTypes.length == product.optionTypes.length"
              >
              Agregar opción
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div
        class="text-right"
        >
        <v-btn
          tile
          color="primary"
          @click="createVariant"
          >
          Crear
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Required } from '@/utils/rules'
import { CreateVariant } from '@/graphql/mutations/products/variant'

export default {
  data () {
    return {
      valid: true,
      images: [],
      imagesUrl: [],
      optionTypes: [null],
      variant: {
        sku: null,
        height: null,
        length: null,
        depth: null,
        weight: null,
        pricesAttributes: [
          {
            amount: 0,
            discount: "0"
          }
        ],
        stockItemsAttributes: [
          {
            stock: 0,
            stockLocationId: 1,
          }
        ],
        variantOptionValuesAttributes: [
          {
            optionValueId: null
          },
        ],
        imagesAttributes: []
      },
      requiredRules: Required
    }
  },

  watch: {
    images (val) {
      this.variant.imagesAttributes = []
      this.imagesUrl = []

      val.forEach( image => {
        this.imagesUrl.push(URL.createObjectURL(image))

        this.variant.imagesAttributes.push({
          image: image,
          name: image.name,
          size: image.size
        })
      })
    },
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.optionTypes[0] = this.product.optionTypes[0]
    this.$forceUpdate()
  },

  methods: {
    addOptions () {
      this.variant.variantOptionValuesAttributes.push({
        optionValueId: null
      })

      this.optionTypes[this.optionTypes.length] = this.product.optionTypes[this.optionTypes.length]
      this.$forceUpdate()
    },

    createVariant () {
      if (this.valid) { this.valid = this.$refs.form.validate() }

      if (this.valid) {
        this.$apollo.mutate({
          mutation: CreateVariant,
          variables: {
            input: {
              productId: this.product.id,
              attributes: this.variant
            }
          }
        }).then( res => {
          this.$emit('reload')
          this.$emit('close')
        })
      }
    },

    removeImage (image) {
      var index = this.images.indexOf(image)
      this.images.splice(index, 1)
    }
  }
}
</script>
