<template>
  <div>
    <div
      :key="properties.length"
      >
      <template
        v-for="property in properties"
        >
        <v-form
          :ref="'property-' + property.id"
          v-model="valid"
          lazy-validation
          >
          <div
            class="d-flex flex-row"
            >
            <v-text-field
              v-model="property.name"
              filled
              label="Nombre"
              :rules="required"
              ></v-text-field>
            <v-text-field
              v-model="property.value"
              class="mx-3"
              filled
              label="Valor"
              :rules="required"
              ></v-text-field>
            <div
              class="text-right mt-3"
              >
              <v-btn
                icon
                class="mr-1 ml-2"
                color="error lighten-2"
                @click="deleteProperty(property.id)"
                >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>

              <v-btn
                icon
                color="success"
                @click="editProperty(property, 'property-' + property.id)"
                >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-form>
      </template>
      <v-form
        v-model="valid"
        ref="new"
        lazy-validation
        >
        <div
          class="d-flex flex-row"
          >
          <v-text-field
            v-model="property.name"
            filled
            label="Nombre"
            placeholder="Bluetooth"
            :rules="required"
            ></v-text-field>
          <v-text-field
            v-model="property.value"
            class="mx-3"
            filled
            label="Valor"
            placeholder="Sí"
            :rules="required"
            ></v-text-field>
          <div
            class="text-right mt-3"
            >
            <v-btn
              text
              color="success"
              @click="createProperty"
              >
              Crear
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
    
    <v-snackbar
      :timeout="3000"
      v-model="alert.active"
      :color="alert.state"
      >
      <v-icon>
        mdi-check
      </v-icon>
      {{ alert.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { CreateProductSimpleProperty, DeleteProductSimpleProperty, EditProductSimpleProperty } from '@/graphql/mutations/product'
import { SimpleProperties } from '@/graphql/queries/products'

export default {
  data () {
    return {
      valid: true,
      required: [
        v => !!v || 'Campo obligatorio.'
      ],
      properties: [],
      property: {
        productId: null,
        name: null,
        value: null,
      },
      alert: {
        active: false,
        state: '',
        message: '',
      }
    }
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.fetchProperties()
    this.property.productId = this.product.id
  },

  methods: {
    editProperty (property, ref) {
      if (this.$refs[ref][0].validate()) {
        this.$apollo.mutate({
          mutation: EditProductSimpleProperty,
          variables: {
            input: {
              id: property.id,
              attributes: {
                name: property.name,
                value: property.value,
              }
            }
          }
        }).then( res => {
          this.fetchProperties()
          this.$refs.new.reset()
          this.alert = {
            active: true,
            state: 'success',
            message: '¡Especificación editada con éxito!',
          }
        })
      }
    },

    createProperty () {
      if (this.$refs.new.validate()) {
        this.$apollo.mutate({
          mutation: CreateProductSimpleProperty,
          variables: {
            input: {
              attributes: this.property
            }
          }
        }).then( res => {
          this.fetchProperties()
          this.$refs.new.reset()
          this.alert = {
            active: true,
            state: 'success',
            message: '¡Especificación creada con éxito!',
          }
        })
      }
    },

    deleteProperty (id) {
      this.$apollo.mutate({
        mutation: DeleteProductSimpleProperty,
        variables: {
          input: {
            id: id
          }
        }
      }).then( res => {
        this.fetchProperties()
        this.$refs.new.reset()
        this.alert = {
          active: true,
          state: 'success',
          message: '¡Especificación eliminada con éxito!',
        }
      })
    },

    fetchProperties () {
      this.$apollo.query({
        query: SimpleProperties,
        variables: {
          id: this.product.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.properties = res.data.productSimpleProperties
      })
    }
  }
}
</script>
