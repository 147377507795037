<template>
  <div>
    <v-row
      class="d-flex"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
      >
      <v-col
        cols="12"
        md="3"
        >
        <div
          >
          <Images
            :product="product"
            :key="product.images.length"
            @reload="$emit('reload')"
            />
        </div>
      </v-col>
      <v-divider
        class="my-3"
        :vertical="$vuetify.breakpoint.mdAndUp"
        ></v-divider>
      <v-col
        cols="12"
        md="9"
        class="px-0"
        >
        <div
          class="px-3"
          >
          <Details
            :product="product"
            @reload="reload"
            />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Images from '@/components/admin/products/Images'
import Details from '@/components/admin/products/Details'

export default {
  data () {
    return {
    }
  },

  components: {
    Images,
    Details
  },

  props: {
    product: {
      required: true,
      type: Object
    }
  },

  methods: {
    reload () {
      this.$emit('reload')
    }
  }
}
</script>
