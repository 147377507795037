<template>
  <v-card>
    <v-card-title>
      Cargar imágenes

      <v-spacer></v-spacer>

      <v-icon
        @click="$emit('close')"
        large
        >
        mdi-close-circle-outline
      </v-icon>
    </v-card-title>

    <v-card-text
      class="text-right"
      >
      <v-file-input
        filled
        v-model="images"
        label="Imágenes"
        multiple
        accept="image/*"
        ></v-file-input>

      <v-btn
        tile
        color="primary"
        @click="edit"
        >
        Guardar
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditVariant } from '@/graphql/mutations/products/variant'

export default {
  data () {
    return {
      images: [],
      imagesAttributes: []
    }
  },

  props: {
    variant: {
      required: true,
      type: Object
    }
  },

  watch: {
    images (val) {
      this.imagesAttributes = []
      val.forEach( image => {
        this.imagesAttributes.push({
          name: image.name,
          size: image.size,
          image: image
        })
      })
    }
  },

  methods: {
    edit () {
      this.$apollo.mutate({
        mutation: EditVariant,
        variables: {
          input: {
            variantId: this.variant.id,
            attributes: {
              imagesAttributes: this.imagesAttributes
            }
          }
        }
      }).then( res => {
        this.$emit('reload')
        this.$emit('close')
      })
    }
  }
}
</script>
