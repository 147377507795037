<template>
  <v-card
    class="mb-3 rounded-lg"
    outlined
    @click="$emit('select', variant)"
    >
    <v-dialog
      v-model="deleteModal"
      width="600"
      :fullscreen="$vuetify.breakpoint.mobile"
      >
      <Delete
        :variant="variant"
        @reload="$emit('reload')"
        @close="deleteModal = false"
        />
    </v-dialog>

    <v-dialog
      v-model="editModal"
      width="600"
      :fullscreen="$vuetify.breakpoint.mobile"
      >
      <Edit
        :variant="variant"
        @reload="$emit('reload')"
        @close="editModal = false"
        />
    </v-dialog>

    <v-card-text
      class="text--primary d-flex flex-row align-center"
      style="gap: 8px"
      >
      <div
        style="width: 40px; height: 40px"
        >
        <v-img
          v-if="variant.images.length"
          :src="url + variant.images[0].thumbUrl"
          width="40"
          height="40"
          contain
          class="rounded-lg"
          ></v-img>
      </div>
      <v-row
        class="align-center"
        v-if="!$vuetify.breakpoint.mobile"
        >
        <v-col
          lg="2"
          >
          {{ variant.sku }}
        </v-col>
        <v-col
          lg="4"
          >
          <template
            v-for="(option, idx) in variant.variantOptionValues"
            >
            <span>
              {{ option.optionValue.optionType.name }}: {{ option.optionValue.value }} {{ (idx + 1) != variant.variantOptionValues.length ? '|' : '' }}
            </span>
          </template>
        </v-col>
        <v-col
          lg="2"
          >
          {{ variant.prices[0].stringify }}
        </v-col>
        <v-col
          lg="2"
          >
          {{ variant.stockItems[0].stock }}
        </v-col>
        <v-col
          class="text-right"
          cols="2"
          v-if="!$vuetify.breakpoint.mobile"
          >
          <v-btn
            icon
            small
            @click="deleteVariant"
            color="error lighten-2"
            class="mr-2"
            >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>

          <v-btn
            icon
            small
            @click="editVariant"
            color="primary"
            >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-else
        >
        <v-col
          cols="6"
          >
          <div
            class="mb-1 text-subtitle-2"
            >
            {{ variant.sku }}
          </div>
          <div
            class="mb-1"
            >
            <template
              v-for="option in variant.variantOptionValues"
              >
              <div>
                {{ option.optionValue.optionType.name }}: {{ option.optionValue.value }}
              </div>
            </template>
          </div>
        </v-col>

        <v-scroll-x-transition
          hide-on-leave
          >
          <v-col
            v-if="showActions"
            cols="6"
            class="text-right"
            >
            <v-btn
              icon
              small
              @click.stop="deleteVariant"
              color="error lighten-2"
              class="mr-2"
              >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              icon
              small
              @click.stop="editVariant"
              color="primary"
              >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-scroll-x-transition>
        <v-scroll-x-transition
          hide-on-leave
          >
          <v-col
            v-if="!showActions"
            cols="6"
            class="text-right"
            >
            <div
              class="mb-1 text-subtitle-2"
              >
              {{ variant.prices[0].stringify }}
            </div>
            <div
              class="mb-1"
              >
              {{ variant.stockItems[0].stock }}
            </div>
          </v-col>
        </v-scroll-x-transition>
      </v-row>  
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Edit from '@/components/admin/products/variants/Edit'
import Delete from '@/components/admin/products/variants/Delete'

export default {
  data () {
    return {
      showActions: false,
      deleteModal: false,
      editModal: false
    }
  },

  components: {
    Edit,
    Delete
  },

  props: {
    variant: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    deleteVariant () {
      this.deleteModal = !this.deleteModal
    },

    editVariant () {
      this.editModal = !this.editModal
    }
  }
}
</script>
